<template>
<b-col sm="6">
  <div class="form-group">
    <label for="zip_code">{{ $t('index') }}<span class="text-danger">*</span></label>
    <input type="text" name="zip_code" class="form-control" v-model="orderData.delivery.zip_code" @input="searchCity" :disabled="!editOn || printed">
    <span class="text-danger">{{ msg }}</span>
  </div>
  <div class="form-group">
    <label for="city">{{ $t('area') }}/{{ $t('city') }}</label>
    <input type="text" class="form-control" v-model="orderData.delivery.city_name" :disabled="disabledCity">
  </div>
  <div class="form-group" v-if="orderData.delivery.delivery_type === 'W2D'">
    <label for="address">Вулиця<span class="text-danger">*</span></label>
    <model-list-select
    :list="streets"
    option-value="STREET_ID"
    option-text="STREET_UA"
    v-model="orderData.delivery.street_ref"
    :selected-item="{ ref: orderData.delivery.street_ref, name: orderData.delivery.street }"
    :placeholder="$t('nothing_selected')"
    @searchchange="searchStreet"
    :disabled="!editOn || printed"></model-list-select>
  </div>
  <b-input-group v-if="orderData.delivery.delivery_type === 'W2D'">
    <b-form-input placeholder="№ дому" v-model="orderData.delivery.house_number" :disabled="!editOn || printed"></b-form-input>
    <b-form-input :placeholder="$t('flat')" v-model="orderData.delivery.flat" :disabled="!editOn || printed"></b-form-input>
    <b-form-input placeholder="№ квартири" v-model="orderData.delivery.apartment_number" :disabled="!editOn || printed"></b-form-input>
  </b-input-group>
  <b-form-group :label="$t('recipient')">
    <b-form-radio v-model="orderData.delivery.entity" name="entity" value="INDIVIDUAL" :disabled="!editOn || printed">{{ $t('fiz_lico') }}</b-form-radio>
    <b-form-radio v-model="orderData.delivery.entity" name="entity" value="COMPANY" :disabled="!editOn || printed">{{ $t('ur_lico') }}</b-form-radio>
    <b-form-radio v-model="orderData.delivery.entity" name="entity" value="PRIVATE_ENTREPRENEUR" :disabled="!editOn || printed">{{ $t('fiz_lico_entrepreneur') }}</b-form-radio>
  </b-form-group>
  <b-form-group v-if="orderData.delivery.entity === 'COMPANY'">
    <label for="edrpou">{{ $t('edrpou') }}</label>
    <input type="text" class="form-control" v-model="orderData.delivery.edrpou" :disabled="!editOn || printed">
  </b-form-group>
  <b-form-group v-if="orderData.delivery.entity === 'PRIVATE_ENTREPRENEUR'">
    <label for="tin">{{ $t('tin') }}</label>
    <input type="text" class="form-control" v-model="orderData.delivery.tin" :disabled="!editOn || printed">
  </b-form-group>
</b-col>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import { ModelListSelect } from 'vue-search-select'
import Swal from 'sweetalert2'
export default {
  components: {
    ModelListSelect
  },
  data: () => ({
    disabledCity: true,
    msg: '',
    streets: [],
    indexData: null
  }),
  computed: {
    ...mapGetters({
      orderData: 'order/order',
      editOn: 'order/editDeliveryData',
      printed: 'order/printed'
    })
  },
  mounted () {
    this.orderData.delivery.entity = this.orderData.delivery.entity !== null ? this.orderData.delivery.entity : 'INDIVIDUAL'
  },
  methods: {
    async searchCity () {
      this.msg = ''
      const postcode = this.orderData.delivery.zip_code.trim()
      if (postcode.length === 5) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'city/getCityByPostcode', { code: postcode }).then(resp => {
          if (resp.data.length > 0) {
            this.orderData.delivery.city_name = resp.data[0].CITY_NAME
            this.indexData = resp.data[0]
          } else {
            this.msg = 'Нічого не знайдено'
          }
        })
      } else {
        this.msg = 'Індекс має бути 5 цифр'
      }
    },
    async searchStreet (value) {
      this.$root.$refs.loading.start()
      if (value.length > 3) {
        const params = {
          region_id: this.indexData.REGION_ID,
          district_id: this.indexData.DISTRICT_ID,
          city_id: this.indexData.CITY_ID,
          street: value,
          postcode: this.orderData.delivery.zip_code.trim()
        }
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'city/getStreetByParams', params).then(resp => {
          if (resp.data) {
            this.streets = resp.data
          } else {
            Swal.fire({
              icon: 'warning',
              title: this.$t('error'),
              text: 'Вулиця не знайдена або по заданому адресу Украпошта не здійснює обслуговування ДКД',
              reverseButtons: true,
              confirmButtonText: this.$t('ok'),
              cancelButtonText: this.$t('cancel')
            })
          }
        })
      }
      this.$root.$refs.loading.finish()
    },
  }
}
</script>
